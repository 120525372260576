import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { addressActions, addressTypes } from './AddressConstants';
import { ServiceClient } from '../../Services/ServiceClient';
import { addressReducer } from './AddressProviderFunctions';
import AddressView from './Components/AddressView';
import SalesAgentModal from '../../Elements/SalesAgentModal';
import AddressButtons from './Components/AddressButtons';
import { Loader } from '@customer/react-relax';
import Warning from './Components/Warning';
import Error from './Components/Error';

const GlobalAddressContext = createContext();

export const useGlobalAddressContext = () => useContext(GlobalAddressContext);

const AddressProvider = ({ setIsShown, salesCode, salesId }) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [addressAction, setAddressAction] = useState(addressActions.Loading);
  const [countryList, setCountryList] = useState([]);

  const isModalOpenedOnSalesIdLevel =
    salesCode === null || salesCode === undefined;

  const [addressState, dispatch] = useReducer(addressReducer, {
    addressType: addressTypes.Primary,
    selectedAddress: {},
    addressList: [],
  });

  useEffect(() => {
    ServiceClient.getCountries(data => {
      setCountryList(data);
      ServiceClient.getAddressListForSalesId(salesId, data => {
        if (data.length === 0) {
          setAddressAction(addressActions.Error);
        } else {
          dispatch({
            type: 'setAddressList',
            addressList: data,
          });
          ServiceClient.getAddressForSalesId(salesId, salesIdDto => {
            if (isModalOpenedOnSalesIdLevel) {
              dispatch({
                type: 'setInitialAddressStateSalesId',
                salesIdDto: salesIdDto,
              });
              setAddressAction(addressActions.View);
            } else {
              ServiceClient.getAddressForSalesCode(salesCode, salesCodeDto => {
                dispatch({
                  type: 'setInitialAddressStateSalesCode',
                  salesCodeDto: salesCodeDto,
                  salesIdDto: salesIdDto,
                });
                setAddressAction(addressActions.View);
              });
            }
          });
        }
      });
    });
  }, [salesCode, salesId, isModalOpenedOnSalesIdLevel]);

  return (
    <SalesAgentModal
      setIsShown={setIsShown}
      titleText={salesCode ?? salesId}
      buttons={
        <AddressButtons
          canSubmit={canSubmit}
          addressAction={addressAction}
          setAddressAction={setAddressAction}
          setIsShown={setIsShown}
          dispatch={dispatch}
        />
      }
      action={addressAction}>
      <GlobalAddressContext.Provider
        value={{
          setCanSubmit,
          addressAction,
          setAddressAction,
          isModalOpenedOnSalesIdLevel,
          addressState,
          dispatch,
          countryList,
          salesCode,
          salesId,
        }}>
        {addressAction === addressActions.Loading ? (
          <Loader />
        ) : addressAction === addressActions.Warning ? (
          <Warning />
        ) : addressAction === addressActions.Error ? (
          <Error />
        ) : (
          <AddressView />
        )}
      </GlobalAddressContext.Provider>
    </SalesAgentModal>
  );
};

export default AddressProvider;
