import classNames from 'classnames';
import React, { useState } from 'react';
import './HeroMenu.css';
import { MenuList } from '@customer/react-relax';
import { OutsideClickHandler } from '../Elements/OutsideClickHandler';
import { PresentationMode } from '../Enums/PresentationMode';
import DisableableMenuListItem from './DisableableMenuListItem';
import { useIntl } from 'react-intl';
import Messages from '../Localization/Messages';

const HeroMenu = props => {
  const [isOpen, setIsOpen] = useState(false);
  const formatMessage = useIntl().formatMessage;

  return (
    <>
      <button
        id="person-actions-menu"
        type="button"
        className="relax-tile-menu"
        onClick={() => setIsOpen(!isOpen)}
      />
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsOpen(false);
        }}>
        <MenuList
          isOpen={isOpen}
          className={classNames('relax-menu hero-menu-edit-button-container')}>
          <DisableableMenuListItem
            name={formatMessage(Messages.edit)}
            onClick={() => {
              props.modeHandler(PresentationMode.Edit);
              setIsOpen(false);
            }}></DisableableMenuListItem>
          {props.isBrokerFunctionsAvailable && (
            <DisableableMenuListItem
              name={formatMessage(Messages.viewBankAccountLog)}
              onClick={() => {
                props.viewBankAccountLogHandler();
                setIsOpen(false);
              }}
              disabled={
                !props.canOpenCostCenterCopyHandler
              }></DisableableMenuListItem>
          )}
          <DisableableMenuListItem
            name={formatMessage(Messages.copyCostCenterTitle)}
            onClick={() => {
              props.openCostCenterCopyHandler();
              setIsOpen(false);
            }}
            disabled={
              !props.canOpenCostCenterCopyHandler
            }></DisableableMenuListItem>
          <DisableableMenuListItem
            name={formatMessage(Messages.seeAddresses)}
            onClick={() => {
              props.openAddressTab();
              setIsOpen(false);
            }}></DisableableMenuListItem>
        </MenuList>
      </OutsideClickHandler>
    </>
  );
};

export default HeroMenu;
