import { ServiceClient } from '../../../../Services/ServiceClient';
import { addressActions } from '../../AddressConstants';
import {
  changeAddressForSalesCode,
  inheritAddressFromSalesId,
} from '../../CommonAddressFunctions';

export const handleClick = (
  salesCode,
  salesId,
  addressState,
  dispatch,
  setAddressAction,
  isModalOpenedOnSalesIdLevel
) => {
  if (isModalOpenedOnSalesIdLevel) {
    changeAddressForSalesId(salesId, addressState.selectedAddress.id, dispatch);
    return;
  }
  if (addressState.uneditedData.isInherited) {
    changeAddress(
      salesCode,
      salesId,
      addressState.selectedAddress.id,
      addressState.salesIdAddress.id,
      dispatch,
      setAddressAction
    );
  } else {
    if (addressState.isInherited) {
      inheritAddressFromSalesId(salesCode, salesId, dispatch);
    } else {
      changeAddress(
        salesCode,
        salesId,
        addressState.selectedAddress.id,
        addressState.salesIdAddress.id,
        dispatch,
        setAddressAction
      );
    }
  }
};

const changeAddressForSalesId = (salesId, addressId, dispatch) => {
  ServiceClient.setAddressForSalesId(salesId, addressId, () => {
    ServiceClient.getAddressListForSalesId(salesId, list => {
      dispatch({ type: 'setSelectedAddress', list });
    });
  });
};

const changeAddress = (
  salesCode,
  salesId,
  addressId,
  salesIdAddressId,
  dispatch,
  setAddressAction
) => {
  if (addressId === salesIdAddressId) {
    setAddressAction(addressActions.Warning);
  } else {
    changeAddressForSalesCode(salesCode, salesId, addressId, dispatch);
  }
};
