import React, { useEffect, useState } from 'react';
import {
  Modal,
  Loader,
  Grid,
  SecondaryButton,
  PrimaryButton,
} from '@customer/react-relax';
import './EditBankAccountOnSalesCode.css';
import { ServiceClient } from '../../Services/ServiceClient';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { Notification } from '../../Elements/Notification';
import { NotificationType } from '../../Enums/NotificationType';
import RowCol from '../../Elements/RowCol';
import BankAccountType from '../../Elements/BankAccountType';
import BankAccountNumber from '../../Elements/BankAccountNumber';
import { PresentationMode } from '../../Enums/PresentationMode';
import { ConvertErrorToNewLines } from '../../Helpers/Converter';

const EditBankAccountOnSalesCode = props => {
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(PresentationMode.Edit);
  const [isValid, setIsValid] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [
    isSalesIdContainsBankAccount,
    setIsSalesidContainsBankAccount,
  ] = useState(true);

  const [modalButtons, setModalButtons] = useState();

  const [notificationMessage, setNotificationMessage] = useState();
  const [notificationType, setNotificationType] = useState(
    NotificationType.General
  );
  const [displayNotification, setDisplayNotification] = useState();

  const [selectedBankAccountType, setSelectedBankAccountType] = useState();
  const [bankName, setBankName] = useState();
  const [bankNumber, setBankNumber] = useState();

  // On initialization / params change
  useEffect(() => {
    if (!props.isShown) return;

    loadInitialData();

    return () => {
      setLoading(true);
      setModalButtons(null);
      setMode(PresentationMode.Edit);
      setDisplayNotification(false);
      setIsPristine(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isShown, props.salesId, props.salesCode]);

  // set valid / pristine
  useEffect(() => {
    const isValid = !!selectedBankAccountType;
    setIsValid(isValid);
    setIsPristine(false);
  }, [selectedBankAccountType, bankNumber]);

  function onCancel() {
    props.setIsShown(false);
  }

  function onSuccessfulSave(response) {
    setMode(PresentationMode.View);

    setModalButtons([
      <SecondaryButton key="close" onClick={onCancel}>
        {formatMessage(Messages.close)}
      </SecondaryButton>,
    ]);

    if (response) {
      setDataFromResponse(response);

      showNotification(
        NotificationType.Success,
        formatMessage(Messages.editBankAccountModalSaveSuccessful, {
          salesCode: props.salesCode || props.salesId,
        })
      );

      setLoading(false);
    } else if (props.salesCode) {
      // account set to default
      ServiceClient.getBankAccountForSalesCode(
        props.salesCode,
        defaultBankAccount => {
          setDataFromResponse(defaultBankAccount);

          showNotification(
            NotificationType.Success,
            formatMessage(Messages.editBankAccountModalSetToDefaultSuccessful, {
              salesCode: props.salesCode,
            })
          );

          setLoading(false);
        },
        onError
      );
    } else {
      throw new Error('Server did not return valid response');
    }
  }

  function onSuccessfulLoad(response) {
    if (response) {
      setDataFromResponse(response);
    }
    validateResponse(response);
    setLoading(false);
    setIsPristine(true);
  }

  function onError(message, error, context) {
    const errorMessage = ConvertErrorToNewLines(context.errorTexts);
    showNotification(NotificationType.Error, errorMessage);

    setLoading(false);

    return true;
  }

  function setDataFromResponse(response) {
    const bankAccount = response?.bankAccount || response;
    setSelectedBankAccountType(bankAccount?.type);
    setBankName(bankAccount?.bankName);
    setBankNumber(bankAccount?.number);
  }

  function validateResponse(response) {
    const bankAccount = response?.bankAccount || response;
    if (!bankAccount?.number) {
      setIsSalesidContainsBankAccount(false);
      const errorMessage = formatMessage(
        Messages.errorMessageCreateBankAccountForSalesId
      );
      showNotification(NotificationType.Error, errorMessage);
      return;
    }
    setIsSalesidContainsBankAccount(true);
  }

  function showNotification(notificationType, message) {
    setDisplayNotification(true);
    setNotificationType(notificationType);
    setNotificationMessage(message);
  }

  function loadInitialData() {
    setLoading(true);

    if (props.salesId) {
      ServiceClient.getBankAccountForSalesId(
        props.salesId,
        onSuccessfulLoad,
        onError
      );
    } else {
      ServiceClient.getBankAccountForSalesCode(
        props.salesCode,
        onSuccessfulLoad,
        onError
      );
    }
  }

  function sendSetRequest() {
    setLoading(true);

    if (props.salesId) {
      ServiceClient.setBankAccountForSalesId(
        props.salesId,
        bankNumber,
        selectedBankAccountType,
        onSuccessfulSave,
        onError
      );
    } else {
      ServiceClient.setBankAccountForSalesCode(
        props.salesCode,
        bankNumber,
        selectedBankAccountType,
        onSuccessfulSave,
        onError
      );
    }
  }

  return (
    <>
      <Modal
        boxClasses="edit-bank-account-modal"
        isOpen={props.isShown}
        onRequestClose={onCancel}
        closeButtonText={formatMessage(Messages.close)}
        title={formatMessage(Messages.editBankAccountModalTitle, {
          salesCode: props.salesCode || props.salesId,
        })}
        buttons={
          modalButtons || [
            <SecondaryButton key="cancel" onClick={onCancel}>
              {formatMessage(Messages.cancel)}
            </SecondaryButton>,
            <PrimaryButton
              key="save"
              onClick={sendSetRequest}
              disabled={
                !isValid || isPristine || !isSalesIdContainsBankAccount
              }>
              {formatMessage(Messages.save)}
            </PrimaryButton>,
          ]
        }>
        {loading && <Loader />}
        {!loading && (
          <Grid>
            {displayNotification && (
              <RowCol className="notification-container">
                <Notification
                  type={notificationType}
                  autoClose={false}
                  hideCloseButton={
                    notificationType === NotificationType.Success
                  }
                  closedCallback={() => setDisplayNotification(false)}>
                  {notificationMessage}
                </Notification>
              </RowCol>
            )}
            <RowCol>
              <BankAccountType
                onChangeValue={setSelectedBankAccountType}
                mode={mode}
                bankName={bankName}
                showBankAccountTypeInViewMode={true}>
                {selectedBankAccountType}
              </BankAccountType>
            </RowCol>
            <RowCol>
              <BankAccountNumber mode={mode} onChangeValue={setBankNumber}>
                {bankNumber}
              </BankAccountNumber>
            </RowCol>
          </Grid>
        )}
      </Modal>
    </>
  );
};

export default EditBankAccountOnSalesCode;
