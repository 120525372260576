import { defineMessages } from 'react-intl';

const Messages = defineMessages({
  errorMessage_0: {
    id: 'errorMessage_Undefined',
    defaultMessage: 'Unknown server error',
  },
  errorMessage_1: {
    id: 'errorMessage_SalesIdIsAlreadyUsed',
    defaultMessage: 'Sales ID is already used',
  },
  errorMessage_2: {
    id: 'errorMessage_DuplicateExternalSalesCode',
    defaultMessage: 'Adm. area "{value}" already in use',
  },
  errorMessage_3: {
    id: 'errorMessage_SalesAgentWasNotFound',
    defaultMessage: 'Sales agent "{value}" was not found',
  },
  errorMessage_4: {
    id: 'errorMessage_SalesAgentHasNoSalesCodes',
    defaultMessage: 'Sales agent "{value}" has no sales codes',
  },
  errorMessage_5: {
    id: 'errorMessage_SalesCodeAlreadyExpired',
    defaultMessage: 'Sales code "{value}" is already expired',
  },
  errorMessage_6: {
    id: 'errorMessage_RequiredFieldWasNotProvided',
    defaultMessage: 'Required field is not provided',
  },
  errorMessage_7: {
    id: 'errorMessage_UnknownBusinessArea',
    defaultMessage: 'Unknown business area "{value}"',
  },
  errorMessage_8: {
    id: 'errorMessage_SalesCodeWasNotFound',
    defaultMessage: 'Sales code "{value}" was not found',
  },
  errorMessage_9: {
    id: 'errorMessage_SalesCodeNotBelongsToSalesAgent',
    defaultMessage:
      'Provided sales code does not belong to provided sales agent',
  },
  errorMessage_10: {
    id: 'errorMessage_BusinessException',
    defaultMessage: 'Server error {value}',
  },
  errorMessage_11: {
    id: 'errorMessage_HttpResponseException',
    defaultMessage: 'HTTP Error {value}',
  },
  errorMessage_12: {
    id: 'errorMessage_BankAccountInvalid',
    defaultMessage: 'Bank account is invalid',
  },
  errorMessage_13: {
    id: 'errorMessage_PartlyExecuted',
    defaultMessage:
      'Partially executed due to error : "{reason}". Successfully updated parameters: {successfullUpdatedparameters}',
  },
  errorMessage_14: {
    id: 'errorMessage_UserIdNotFound',
    defaultMessage: 'User ID not found',
  },
  maxAllowedCharacters: {
    id: 'maxAllowedCharacters',
    defaultMessage: 'Maximum allowed characters: {maxLimitCharacter}.',
  },
  searchPageInputPlaceholder: {
    id: 'searchPageInputPlaceholder',
    defaultMessage: 'Search by Sales ID',
  },
  searchPageResultInfo: {
    id: 'searchPageResultInfo',
    defaultMessage:
      'Your search "{query}" gave {length} hits from sales agent registry for {legalUnit}\\{businessArea}. ',
  },
  noResultFromAD: {
    id: 'noResultFromAD',
    defaultMessage: 'No any record found in AD',
  },
  searchPageHeaderTitle: {
    id: 'searchPageHeaderTitle',
    defaultMessage: 'Search or create a new sales agent',
  },
  addUserBtn: {
    id: 'addUserBtn',
    defaultMessage: 'Add User',
  },
  userId: {
    id: 'userId',
    defaultMessage: 'User ID',
  },
  salesId: {
    id: 'salesId',
    defaultMessage: 'Sales ID',
  },
  salesCode: {
    id: 'salesCode',
    defaultMessage: 'Sales Code',
  },
  costCenter: {
    id: 'costCenter',
    defaultMessage: 'Cost Center',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit',
  },
  copyCostCenterTitle: {
    id: 'copyCostCenterTitle',
    defaultMessage: 'Copy Cost center (s) to new user',
  },
  seeAddresses: {
    id: 'seeAddresses',
    defaultMessage: 'Open Address Tab',
  },
  save: {
    id: 'save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  update: {
    id: 'update',
    defaultMessage: 'Update',
  },
  salesAgent: {
    id: 'salesAgent',
    defaultMessage: 'Sales Agent',
  },
  person: {
    id: 'person',
    defaultMessage: 'Person',
  },
  commission: {
    id: 'commission',
    defaultMessage: 'Commission',
  },
  invalid: {
    id: 'invalid',
    defaultMessage: 'Invalid',
  },
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  externalSalesCode: {
    id: 'externalSalesCode',
    defaultMessage: 'External sales code',
  },
  activationDate: {
    id: 'activationDate',
    defaultMessage: 'Activation date',
  },
  endDate: {
    id: 'endDate',
    defaultMessage: 'End date',
  },
  revoke: {
    id: 'revoke',
    defaultMessage: 'Revoke',
  },
  addSalesCode: {
    id: 'addSalesCode',
    defaultMessage: 'Add sales code',
  },
  create: {
    id: 'create',
    defaultMessage: 'Create',
  },
  activeSalesCodes: {
    id: 'activeSalesCodes',
    defaultMessage: 'Active sales codes',
  },
  close: {
    id: 'close',
    defaultMessage: 'Close',
  },
  channel: {
    id: 'channel',
    defaultMessage: 'Channel',
  },
  choose: {
    id: 'choose',
    defaultMessage: 'Choose',
  },
  region: {
    id: 'region',
    defaultMessage: 'Region',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'Optional',
  },
  presentationModeAddHeader: {
    id: 'presentationModeAddHeader',
    defaultMessage: 'Add sales code for {salesId}',
  },
  customer: {
    id: 'customer',
    defaultMessage: 'Customer',
  },
  presentationModeEditHeader: {
    id: 'presentationModeEditHeader',
    defaultMessage: 'Edit sales code {salesCode}',
  },
  customerNumber: {
    id: 'customerNumber',
    defaultMessage: 'Customer number',
  },
  presentationModeViewHeader: {
    id: 'presentationModeViewHeader',
    defaultMessage: 'View sales code {salesCode}',
  },
  searchDropDownCantFindMessage: {
    id: 'searchDropDownCantFindMessage',
    defaultMessage: 'Can not find {searchSubjectName} starting with "{text}" ',
  },
  searchDropDownSearchorChoose: {
    id: 'searchDropDownSearchorChoose',
    defaultMessage: 'Search or choose {searchSubjectName}',
  },
  revokeConfirmationTitle: {
    id: 'revokeConfirmationTitle',
    defaultMessage: 'Revoke sales code',
  },
  yesRevoke: {
    id: 'yesRevoke',
    defaultMessage: 'Yes, revoke',
  },
  revokeConfirmationMessage: {
    id: 'revokeConfirmationMessage',
    defaultMessage: 'You are revoking the following sales codes',
  },
  historicalSalesCodesTitle: {
    id: 'historicalSalesCodesTitle',
    defaultMessage: 'Historical sales codes',
  },
  noHistoricalSalesCode: {
    id: 'noHistoricalSalesCode',
    defaultMessage: 'There are no historical sales codes',
  },
  copyAndCreate: {
    id: 'copyAndCreate',
    defaultMessage: 'Copy and create',
  },
  chooseCostCenterToCopy: {
    id: 'chooseCostCenterToCopy',
    defaultMessage: 'Choose Cost center(s) to copy',
  },
  chooseAll: {
    id: 'chooseAll',
    defaultMessage: 'Choose all',
  },
  requiredSelectAtLeastOneCostCenter: {
    id: 'requiredSelectAtLeastOneCostCenter',
    defaultMessage: 'Select at least one cost center',
  },
  createNewUser: {
    id: 'createNewUser',
    defaultMessage: 'Create new user',
  },
  notificationUserIdNotFoundInAD: {
    id: 'notificationUserIdNotFoundInAD',
    defaultMessage:
      'User ID doesn’t exist in AD. Are you sure you want to add this user as a sales agent?',
  },
  notificationSuccessCopyCostCenter: {
    id: 'notificationSuccessCopyCostCenter',
    defaultMessage:
      'You have successfully copied Cost center(s) and created a new user and sales codes',
  },
  validationSalesId: {
    id: 'validationSalesId',
    defaultMessage: 'Should be 3-8 characters',
  },
  notificationErrorRenameSalesAgent: {
    id: 'notificationErrorRename',
    defaultMessage: "Couldn't rename sales agent.",
  },
  notificationErrorUpdateSalesAgent: {
    id: 'notificationErrorRename',
    defaultMessage: "Couldn't update sales agent.",
  },
  errorMessageUserIdAlreadyExist: {
    id: 'errorMessageUserIdAlreadyExist',
    defaultMessage: 'You are trying to create an existing user',
  },
  errorMessageUnexpectedError: {
    id: 'errorMessageUnexpectedError',
    defaultMessage: 'Server returned unexpected result',
  },
  redirecting: {
    id: 'redirecting',
    defaultMessage: 'Redirecting',
  },
  notFound: {
    id: 'notFound',
    defaultMessage: 'Not Found',
  },
  error: {
    id: 'error',
    defaultMessage: 'Error',
  },
  tryToRefresh: {
    id: 'tryToRefresh',
    defaultMessage: 'Try to refresh',
  },
  goToSearch: {
    id: 'goToSearch',
    defaultMessage: 'Go to search',
  },
  missingValue: {
    id: 'missingValue',
    defaultMessage: 'Missing value',
  },
  salesIdNotFound: {
    id: 'salesIdNotFound',
    defaultMessage: 'Sales ID Not Found',
  },
  salesIdNotFoundDescription: {
    id: 'salesIdNotFoundDescription',
    defaultMessage: 'Please check if the SalesId is correct',
  },
  errorPageTitle: {
    id: 'errorPageTitle',
    defaultMessage: 'Something went wrong',
  },
  SalesAgentLUDifferentFromSelectedLU: {
    id: 'SalesAgentLUDifferentFromSelectedLU',
    defaultMessage:
      'Legal unit of sales code\'s region "{salesAgentLU}" doesn\'t match legal unit in user preferences "{selectedLU}". Channels, regions and cost centers will be shown for legal unit "{salesAgentLU}".',
  },
  SalesIdExistsInAnotherCountry: {
    id: 'isSalesIdExistsInAnotherCountry',
    defaultMessage: 'But "{query}" exists in another country.',
  },
  groupEmail: {
    id: 'groupEmail',
    defaultMessage: 'Alert email',
  },
  digital: {
    id: 'digital',
    defaultMessage: 'Digital',
  },
  bankAccount: {
    id: 'bankAccount',
    defaultMessage: 'Bank account',
  },
  xmlExport: {
    id: 'xmlExport',
    defaultMessage: 'XML export',
  },
  typeOfAccount: {
    id: 'typeOfAccount',
    defaultMessage: 'Type of account',
  },
  bankName: {
    id: 'bankName',
    defaultMessage: 'Bank name',
  },
  viewBankAccountLog: {
    id: 'viewBankAccountLog',
    defaultMessage: 'View bank account log',
  },
  bankAccountLogModalTitle: {
    id: 'bankAccountLogModalTitle',
    defaultMessage: 'Bank account log for {salesCode}',
  },
  bankAccountLogUpdated: {
    id: 'bankAccountLogUpdated',
    defaultMessage: 'Updated',
  },
  bankAccountLogUpdatedBy: {
    id: 'bankAccountLogUpdatedBy',
    defaultMessage: 'Updated by',
  },
  bankAccountLogBankAccount: {
    id: 'bankAccountLogBankAccount',
    defaultMessage: 'Bank account',
  },
  bankAccountLogBankAccountType: {
    id: 'bankAccountLogBankAccountType',
    defaultMessage: 'Bank account type',
  },
  bankAccountLogBankName: {
    id: 'bankAccountLogBankName',
    defaultMessage: 'Bank name',
  },
  bankAccountLogShared: {
    id: 'bankAccountLogShared',
    defaultMessage: 'Shared',
  },
  yes: {
    id: 'yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'no',
    defaultMessage: 'No',
  },
  editBankAccount: {
    id: 'editBankAccount',
    defaultMessage: 'Edit bank account',
  },
  editAddress: {
    id: 'editAddress',
    defaultMessage: 'Edit Address',
  },
  editBankAccountModalTitle: {
    id: 'editBankAccountModalTitle',
    defaultMessage: 'Editing bank account for {salesCode}',
  },
  editBankAccountModalSaveSuccessful: {
    id: 'editBankAccountModalSaveSuccessful',
    defaultMessage: 'Bank account successfully saved for {salesCode}',
  },
  editBankAccountModalSetToDefaultSuccessful: {
    id: 'editBankAccountModalSetToDefaultSuccessful',
    defaultMessage:
      'Bank account successfully reset to default for {salesCode}',
  },
  viewBrokerCostRates: {
    id: 'viewBrokerCostRates',
    defaultMessage: 'Commission',
  },
  brokerCostType_Property: {
    id: 'brokerCostType_Property',
    defaultMessage: 'Property',
  },
  brokerCostType_Liability: {
    id: 'brokerCostType_Liability',
    defaultMessage: 'Liability',
  },
  brokerCostType_Person: {
    id: 'brokerCostType_Person',
    defaultMessage: 'Person',
  },
  brokerCostType_Transport: {
    id: 'brokerCostType_Transport',
    defaultMessage: 'Transport',
  },
  brokerCostType_Casco: {
    id: 'brokerCostType_Casco',
    defaultMessage: 'Casco',
  },
  brokerCostType_PersonalCommission: {
    id: 'brokerCostType_PersonalCommission',
    defaultMessage: 'Personal commission',
  },
  brokerCostType_MotorCommission: {
    id: 'brokerCostType_MotorCommission',
    defaultMessage: 'Motor commission',
  },
  brokerCostRatesModalTitle: {
    id: 'brokerCostRatesModalTitle',
    defaultMessage: 'Broker cost rates for {salesCode}',
  },
  brokerCostRatesModalTitleWithCustomerNumber: {
    id: 'brokerCostRatesModalTitleWithCustomerNumber',
    defaultMessage:
      'Broker cost rates for {salesCode} (customer {customerNumber})',
  },
  brokerCostRatesModalUseBrokerCost: {
    id: 'brokerCostRatesModalUseBrokerCost',
    defaultMessage: 'Use broker cost',
  },
  brokerCostRatesModalUsePersonalCommission: {
    id: 'brokerCostRatesModalUsePersonalCommission',
    defaultMessage: 'Use personal commission',
  },
  brokerCostRatesModalUseMotorCommission: {
    id: 'brokerCostRatesModalUseMotorCommission',
    defaultMessage: 'Use motor commission',
  },
  brokerCostRatesModalInheritCommissionHandling: {
    id: 'brokerCostRatesModalInheritCommissionHandling',
    defaultMessage: 'Inherit commission handling',
  },
  brokerCostRatesModalEffective: {
    id: 'brokerCostRatesModalEffective',
    defaultMessage: 'Effective',
  },
  brokerCostRatesModalExpires: {
    id: 'brokerCostRatesModalExpires',
    defaultMessage: 'Expires',
  },
  brokerCostRatesModalUpdated: {
    id: 'brokerCostRatesModalUpdated',
    defaultMessage: 'Updated',
  },
  brokerCostRatesModalUpdatedBy: {
    id: 'brokerCostRatesModalUpdatedBy',
    defaultMessage: 'Updated by',
  },
  brokerCostRatesModalMappedToIfLogin: {
    id: 'brokerCostRatesModalMappedToIfLogin',
    defaultMessage: 'Mapped to IfLogin',
  },
  brokerCostRatesModalNoData: {
    id: 'brokerCostRatesModalNoData',
    defaultMessage: 'Net pricing',
  },
  brokerCostRatesModalInfoHeader: {
    id: 'brokerCostRatesModalInfoHeader',
    defaultMessage: 'Info',
  },
  brokerCostRatesModalRatesHeader: {
    id: 'brokerCostRatesModalRatesHeader',
    defaultMessage: 'Rates',
  },
  brokerCostRatesModalCommissionHeader: {
    id: 'brokerCostRatesModalCommissionHeader',
    defaultMessage: 'Commission',
  },
  brokerCostRatesModalNoCustomer: {
    id: 'brokerCostRatesModalNoCustomer',
    defaultMessage: 'No customer',
  },
  brokerCostRatesModalCustomerNotFound: {
    id: 'brokerCostRatesModalCustomerNotFound',
    defaultMessage: 'Customer {customerNumber} not found',
  },
  brokerCostRatesModalCustomerHasNoSpecificRates: {
    id: 'brokerCostRatesModalCustomerHasNoSpecificRates',
    defaultMessage: 'Customer {customerNumber} has no specific rates',
  },
  brokerCostRatesModalRatesSuccessfullySaved: {
    id: 'brokerCostRatesModalRatesSuccessfullySaved',
    defaultMessage: 'Rates successfully saved',
  },
  brokerCostRatesModalCustomerOrLegalNumber: {
    id: 'brokerCostRatesModalCustomerOrLegalNumber',
    defaultMessage: 'Customer/legal number',
  },
  brokerCostRatesModalDropdownHeader: {
    id: 'brokerCostRatesModalDropdownHeader',
    defaultMessage: 'Search for or select customer',
  },
  errorMessageCreateBankAccountForSalesId: {
    id: 'errorMessageCreateBankAccountForSalesId',
    defaultMessage: 'Create bank account on sales id level first',
  },
  commissionHistoryModalTitle: {
    id: 'commissionHistoryModalTitle',
    defaultMessage: 'Commission history for {salesCode}',
  },
  commissionHistoryModalTitleWithCustomerNumber: {
    id: 'commissionHistoryModalTitleWithCustomerNumber',
    defaultMessage:
      'Commission history for {salesCode} (customer {customerNumber})',
  },
  viewCommissionHistory: {
    id: 'viewCommissionHistory',
    defaultMessage: 'Commission history',
  },
  commissionHistoryModalUseBrokerCost: {
    id: 'commissionHistoryModalUseBrokerCost',
    defaultMessage: 'Use broker cost',
  },
  commissionHistoryModalUsePersonalCommission: {
    id: 'commissionHistoryModalUsePersonalCommission',
    defaultMessage: 'Use personal commission',
  },
  commissionHistoryModalUseMotorCommission: {
    id: 'commissionHistoryModalUseMotorCommission',
    defaultMessage: 'Use motor commission',
  },
  commissionHistoryModalInheritCommissionHandling: {
    id: 'commissionHistoryModalInheritCommissionHandling',
    defaultMessage: 'Inherit commission handling',
  },
  commissionHistoryModalExpires: {
    id: 'commissionHistoryModalExpires',
    defaultMessage: 'Expires',
  },
  commissionHistoryModalUpdated: {
    id: 'commissionHistoryModalUpdated',
    defaultMessage: 'Updated',
  },
  commissionHistoryModalUpdatedBy: {
    id: 'commissionHistoryModalUpdatedBy',
    defaultMessage: 'Updated by',
  },
  commissionHistoryModalFilter: {
    id: 'commissionHistoryModalFilter',
    defaultMessage: 'Filter:',
  },
  commissionHistoryModalHistory: {
    id: 'commissionHistoryModalHistory',
    defaultMessage: 'History:',
  },
  commissionHistoryModalCustomerNumber: {
    id: 'commissionHistoryModalCustomerNumber',
    defaultMessage: 'Customer number',
  },
  commissionHistoryModalNoDataFound: {
    id: 'commissionHistoryModalNoDataFound',
    defaultMessage: 'No data found!',
  },
  commissionHistoryModalCustomerValue: {
    id: 'commissionHistoryModalCustomerValue',
    defaultMessage: 'Customer value',
  },
  commissionHistoryModalShowCustomerValue: {
    id: 'commissionHistoryModalShowCustomerValue',
    defaultMessage: 'Show customer value',
  },
  commissionHistoryModalShowExpired: {
    id: 'commissionHistoryModalShowExpired',
    defaultMessage: 'Show expired',
  },
  updatedByItem_SystemName_IfLogin: {
    id: 'updatedByItem_SystemName_IfLogin',
    defaultMessage: 'IfLogin',
  },
  updatedByItem_SystemName_IfLoginSyncBatch: {
    id: 'updatedByItem_SystemName_IfLoginSyncBatch',
    defaultMessage: 'IfLogin Sync',
  },
  updatedByItem_SystemName_MyBusiness: {
    id: 'updatedByItem_SystemName_MyBusiness',
    defaultMessage: 'MyBusiness',
  },
  updatedByItem_SystemName_SalesAgentApp: {
    id: 'updatedByItem_SystemName_SalesAgentApp',
    defaultMessage: 'SalesAgentApp',
  },
  updatedByItem_SystemName_Waypoint: {
    id: 'updatedByItem_SystemName_Waypoint',
    defaultMessage: 'Waypoint',
  },
  updatedByItem_SystemName_Unknown: {
    id: 'updatedByItem_SystemName_Unknown',
    defaultMessage: 'Unknown',
  },
  updatedByItem_SystemUserName: {
    id: 'updatedByItem_SystemUserName',
    defaultMessage: 'System',
  },
  updatedByItem_UnknownUserName: {
    id: 'updatedByItem_UnknownUserName',
    defaultMessage: 'Unknown',
  },
  updatedByItem_NoData: {
    id: 'updatedByItem_NoData',
    defaultMessage: 'No data',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone',
  },
  commissionPageWaypointNumber: {
    id: 'commissionPageWaypointNumber',
    defaultMessage: 'Waypoint number',
  },
  commissionPageCustomer: {
    id: 'commissionPageCustomer',
    defaultMessage: 'Customer',
  },
  commissionPageOrganizationNumber: {
    id: 'commissionPageOrganizationNumber',
    defaultMessage: 'Organization number',
  },
  commissionPageFeeAcceptance: {
    id: 'commissionPageFeeAcceptance',
    defaultMessage: 'Brokercost accept',
  },
  commissionPagePersonalAcceptance: {
    id: 'commissionPagePersonalAcceptance',
    defaultMessage: 'Personal acceptance',
  },
  commissionPageMotorAcceptance: {
    id: 'commissionPageMotorAcceptance',
    defaultMessage: 'Motor acceptance',
  },
  commissionPageProperty: {
    id: 'commissionPageProperty',
    defaultMessage: 'Property',
  },
  commissionPageLiability: {
    id: 'commissionPageLiability',
    defaultMessage: 'Liability',
  },
  commissionPagePerson: {
    id: 'commissionPagePerson',
    defaultMessage: 'Person',
  },
  commissionPageTransport: {
    id: 'commissionPageTransport',
    defaultMessage: 'Transport',
  },
  commissionPageComprehensive: {
    id: 'commissionPageComprehensive',
    defaultMessage: 'Casco',
  },
  commissionPagePersonalCommission: {
    id: 'commissionPagePersonalCommission',
    defaultMessage: 'Personal commission',
  },
  commissionPageMotorCommission: {
    id: 'commissionPageMotorCommission',
    defaultMessage: 'Motor commission',
  },
  canBePortfolioResponsible: {
    id: 'canBePortfolioResponsible',
    defaultMessage: 'Can be portfolio responsible',
  },
  commissionPageValidFrom: {
    id: 'commissionPageValidFrom',
    defaultMessage: 'Valid from',
  },
  commissionPageValidTo: {
    id: 'commissionPageValidTo',
    defaultMessage: 'Valid to',
  },
  commissionPageUpdatedBy: {
    id: 'commissionPageUpdatedBy',
    defaultMessage: 'Updated by',
  },
  commissionPageHistoricalCommission: {
    id: 'commissionPageHistoricalCommission',
    defaultMessage: 'Historical commission',
  },
  customerDropDownPlaceholder: {
    id: 'customerDropDownPlaceholder',
    defaultMessage: 'WP number, legal number or name',
  },
  customerDropDownSearchSubject: {
    id: 'customerDropDownSearchSubject',
    defaultMessage: 'customer',
  },
  customerDropDownAddCustomer: {
    id: 'customerDropDownAddCustomer',
    defaultMessage: 'Add new customer',
  },
  additionalName: {
    id: 'additionalName',
    defaultMessage: 'Additional name',
  },
  salesCodeConversionPageTitle: {
    id: 'salesCodeConversionPageTitle',
    defaultMessage: 'Salescode conversions',
  },
  salesCodeConversionPageStatistics: {
    id: 'salesCodeConversionPageStatistics',
    defaultMessage: 'Conversion statistics',
  },
  salesCodeConversionPageChannelName: {
    id: 'salesCodeConversionPageChannelName',
    defaultMessage: 'Channel name',
  },
  salesCodeConversionPageSalescodes: {
    id: 'salesCodeConversionPageSalescodes',
    defaultMessage: 'Salescodes',
  },
  salesCodeConversionPageOldSalescode: {
    id: 'salesCodeConversionPageOldSalescode',
    defaultMessage: 'Old salescode',
  },
  salesCodeConversionPageOldSalescodes: {
    id: 'salesCodeConversionPageOldSalescodes',
    defaultMessage: 'Old salescodes',
  },
  salesCodeConversionPageConvertedSalescodes: {
    id: 'salesCodeConversionPageConvertedSalescodes',
    defaultMessage: 'Converted salescodes',
  },
  salesCodeConversionPageNewSalescode: {
    id: 'salesCodeConversionPageNewSalescode',
    defaultMessage: 'New salescode',
  },
  salesCodeConversionPageStartDate: {
    id: 'salesCodeConversionPageStartDate',
    defaultMessage: 'Start date of renewal step for convertion',
  },
  salesCodeConversionPageMappingDisabled: {
    id: 'salesCodeConversionPageMappingDisabled',
    defaultMessage:
      'Mapping disabled for: {oldSalesCode}​​​​​​​ => {newSalesCode} ',
  },
  salesCodeConversionPageMappingErrorDuringDisable: {
    id: 'salesCodeConversionPageMappingErrorDuringDisable',
    defaultMessage:
      'Error occured during disable of mapping: {oldSalesCode} => {newSalesCode}',
  },
  salesCodeConversionPageMappingDone: {
    id: 'salesCodeConversionPageMappingDone',
    defaultMessage: 'Mapping done for: {oldSalesCode} => {newSalesCode}',
  },
  salesCodeConversionPageMappingErrorDuringMapping: {
    id: 'salesCodeConversionPageMappingErrorDuringMapping',
    defaultMessage:
      'Error occured during set of mapping: {oldSalesCode} => {newSalesCode}',
  },
  salesCodeConversionPageEnableButton: {
    id: 'salesCodeConversionPageEnableButton',
    defaultMessage: 'Enable',
  },
  salesCodeConversionPageDisableButton: {
    id: 'salesCodeConversionPageDisableButton',
    defaultMessage: 'Disable',
  },
  salesCodeConversionPageChannelNameInUserMetadata: {
    id: 'salesCodeConversionPageChannelNameInUserMetadata',
    defaultMessage: 'Channel name in Usermetadata',
  },
  salesCodeConversionPageChannelNameInWayPoint: {
    id: 'salesCodeConversionPageChannelNameInWayPoint',
    defaultMessage: 'Channel name in Waypoint',
  },
  salesCodeConversionPageGlCode: {
    id: 'salesCodeConversionPageGlCode',
    defaultMessage: 'GL Code',
  },
  salesCodeConversionPageSalesCodesInUserMetadata: {
    id: 'salesCodeConversionPageSalesCodesInUserMetadata',
    defaultMessage: 'Salescodes in Usermetadata',
  },
  salesCodeConversionPageConvertedSalesCodesToWayPoint: {
    id: 'salesCodeConversionPageConvertedSalesCodesToWayPoint',
    defaultMessage: 'Converted salescodes to Waypoint',
  },
  salesCodeConversionPageConvertedSalesCodesMappedInRenewalStep: {
    id: 'salesCodeConversionPageConvertedSalesCodesMappedInRenewalStep',
    defaultMessage: 'Salescodes "mapped" in renewal step',
  },
  salesCodeConversionPageConvertedDate: {
    id: 'salesCodeConversionPageConvertedDate',
    defaultMessage: 'Start date of renewal step',
  },
  salesCodeConversionPageDragHeadersToGroupBy: {
    id: 'salesCodeConversionPageDragHeadersToGroupBy',
    defaultMessage: 'Drag headers here to group by',
  },
  notApplicable: {
    id: 'notApplicable',
    defaultMessage: 'Not applicable',
  },
  addressComponentFormatSelectorStreet: {
    id: 'addressComponentFormatSelectorStreet',
    defaultMessage: 'Street Address',
  },
  addressComponentFormatSelectorForeign: {
    id: 'addressComponentFormatSelectorForeign',
    defaultMessage: 'Foreign Address',
  },
  addressComponentFormatSelectorFreeText: {
    id: 'addressComponentFormatSelectorFreeText',
    defaultMessage: 'Free Text Address',
  },
  addressComponentFormatSelectorUnstructured: {
    id: 'addressComponentFormatSelectorUnstructured',
    defaultMessage: 'Unstructured Address',
  },
  addressComponentFormatSelectorPostBox: {
    id: 'addressComponentFormatSelectorPostBox',
    defaultMessage: 'Post Box Address',
  },
  addressComponentCheckBoxLabel: {
    id: 'addressComponentCheckBoxLabel',
    defaultMessage: 'Is address inherited from sales id',
  },
  addressComponentEditAddress: {
    id: 'addressComponentEditAddress',
    defaultMessage: 'Edit address',
  },
  addressComponentCreateAddress: {
    id: 'addressComponentCreateAddress',
    defaultMessage: 'Create address',
  },
  addressComponentWarning: {
    id: 'addressComponentWarning',
    defaultMessage:
      'You are about to assign an address which is already set on Sales Id level.',
  },
  addressComponentWarningQuestion: {
    id: 'addressComponentWarningQuestion',
    defaultMessage: 'Do you want to continue or inherit the address?',
  },
  addressComponentWarningInherit: {
    id: 'addressComponentWarningInherit',
    defaultMessage: 'Inherit',
  },
  addressComponentWarningContinue: {
    id: 'addressComponentWarningContinue',
    defaultMessage: 'Continue',
  },
  addressComponentError: {
    id: 'addressComponentError',
    defaultMessage: 'No address found!',
  },
  addressComponentSearchPlaceholder: {
    id: 'addressComponentSearchPlaceholder',
    defaultMessage: 'Search addresses',
  },
  addressComponentCoAdd: {
    id: 'addressComponentCoAdd',
    defaultMessage: 'Add C/o',
  },
  addressComponentCoRemove: {
    id: 'addressComponentCoRemove',
    defaultMessage: 'Remove C/o',
  },
  addressComponentCoType: {
    id: 'addressComponentCoType',
    defaultMessage: 'Type',
  },
  addressComponentCoName: {
    id: 'addressComponentCoName',
    defaultMessage: 'C/o',
  },
  addressComponentCountrySelectorLabel: {
    id: 'addressComponentCountrySelectorLabel',
    defaultMessage: 'Country',
  },
  addressComponentPostalCodeLabel: {
    id: 'addressComponentPostalCodeLabel',
    defaultMessage: 'Postal code',
  },
  addressComponentPostalDistrictLabel: {
    id: 'addressComponentPostalDistrictLabel',
    defaultMessage: 'District',
  },
  addressComponentAddressLine1: {
    id: 'addressComponentAddressLine1',
    defaultMessage: 'Address Line 1',
  },
  addressComponentAddressLine2: {
    id: 'addressComponentAddressLine2',
    defaultMessage: 'Address Line 2',
  },
  addressComponentAddressLine3: {
    id: 'addressComponentAddressLine3',
    defaultMessage: 'Address Line 3',
  },
  addressComponentPostBoxNumber: {
    id: 'addressComponentPostBoxNumber',
    defaultMessage: 'Box number',
  },
  addressComponentPostBoxName: {
    id: 'addressComponentPostBoxName',
    defaultMessage: 'Box name',
  },
  addressComponentStreetName: {
    id: 'addressComponentStreetName',
    defaultMessage: 'Street name',
  },
  addressComponentStreetHouseNumber: {
    id: 'addressComponentStreetHouseNumber',
    defaultMessage: 'Number',
  },
  addressComponentStreetHouseLetter: {
    id: 'addressComponentStreetHouseLetter',
    defaultMessage: 'Letter',
  },
  addressComponentStreetApartmentNumber: {
    id: 'addressComponentStreetApartmentNumber',
    defaultMessage: 'Apartment Number',
  },
  addressComponentStreetAdditionalLine: {
    id: 'addressComponentStreetAdditionalLine',
    defaultMessage: 'Additional Address Line',
  },
  addressComponentFreeTextAdditionalLine: {
    id: 'addressComponentFreeTextAdditionalLine',
    defaultMessage: 'Additional Address Line',
  },
});

export default Messages;
