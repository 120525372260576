import React from 'react';
import '@relax/modal';
import './SalesAgentModal.css';

const SalesAgentModal = ({
  children,
  setIsShown,
  titleText,
  buttons,
  action,
}) => {
  return (
    <div className="relax-modal relax-is-open">
      <div className="relax-modal-box address-modal-container">
        <button
          type="button"
          className="relax-modal-close"
          onClick={() => setIsShown(false)}></button>
        <div className="relax-modal-content">
          <p className="title">{titleText}</p>
          {children}
        </div>

        <div className="relax-modal-footer">{buttons}</div>
      </div>
    </div>
  );
};

export default SalesAgentModal;
