import { ServiceClient } from '../../../../Services/ServiceClient';
import { addressActions, addressFormats } from '../../AddressConstants';

export const onSubmit = (
  event,
  salesCode,
  salesId,
  isModalOpenedOnSalesIdLevel,
  address,
  setAddressAction,
  format,
  dispatch
) => {
  event.preventDefault();

  if (isModalOpenedOnSalesIdLevel) {
    ServiceClient.createAddressForSalesId(
      salesId,
      {
        ...address,
        countryId: address.country.id,
        format,
      },
      addressId => {
        handleSuccessfulCreateResult(
          { ...address, format, id: addressId },
          salesId,
          isModalOpenedOnSalesIdLevel,
          dispatch
        );
      }
    );
  } else {
    ServiceClient.createAddressForSalesCode(
      salesCode,
      {
        ...address,
        countryId: address.country.id,
        format,
      },
      addressId => {
        handleSuccessfulCreateResult(
          { ...address, format, id: addressId },
          salesId,
          isModalOpenedOnSalesIdLevel,
          dispatch
        );
      }
    );
  }
  setAddressAction(addressActions.View);
};

export const checkRequiredFields = (address, format) => {
  if (format === addressFormats.Street) {
    return checkCommonRequiredFields(address) && address.streetName?.length > 0;
  }
  if (format === addressFormats.Foreign) {
    return (
      address.addressLine1?.length > 0 && address.country?.name?.length > 0
    );
  }
  if (format === addressFormats.Freetext) {
    return checkCommonRequiredFields(address);
  }
  if (format === addressFormats.POBox) {
    return (
      checkCommonRequiredFields(address) && address.pOBoxNumber?.length > 0
    );
  }
  if (format === addressFormats.Unstructured) {
    return (
      checkCommonRequiredFields(address) && address.addressLine1?.length > 0
    );
  }
};

export const areAddressesEqual = (address, initialAddress) => {
  // for cases when user enters text in an empty field but then manually deletes the text
  const keys1 = Object.keys(address);
  for (let key of keys1) {
    if (address[key] === '') {
      delete address[key];
    }
  }

  if (address.format === addressFormats.Street) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.streetName === initialAddress.streetName &&
      address.houseNumber === initialAddress.houseNumber &&
      address.houseLetter === initialAddress.houseLetter &&
      address.apartmentNumber === initialAddress.apartmentNumber &&
      address.additionalAddress === initialAddress.additionalAddress
    );
  }
  if (address.format === addressFormats.POBox) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.pOBoxNumber === initialAddress.pOBoxNumber &&
      address.pOBoxFacilityName === initialAddress.pOBoxFacilityName
    );
  }
  if (address.format === addressFormats.Foreign) {
    return (
      checkCareOfFieldsAreEqual(address, initialAddress) &&
      address.addressLine1 === initialAddress.addressLine1 &&
      address.addressLine2 === initialAddress.addressLine2 &&
      address.addressLine3 === initialAddress.addressLine3 &&
      address.country?.name === initialAddress.country?.name
    );
  }
  if (address.format === addressFormats.Unstructured) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.addressLine1 === initialAddress.addressLine1 &&
      address.addressLine2 === initialAddress.addressLine2 &&
      address.addressLine3 === initialAddress.addressLine3
    );
  }
  if (address.format === addressFormats.Freetext) {
    return (
      checkCommonFieldsAreEqual(address, initialAddress) &&
      address.additionalAddress === initialAddress.additionalAddress
    );
  }
};

const checkCareOfFieldsAreEqual = (address, initialAddress) => {
  return (
    address.careOf?.name === initialAddress.careOf?.name &&
    address.careOf?.prefix === initialAddress.careOf?.prefix
  );
};

const checkCommonFieldsAreEqual = (address, initialAddress) => {
  return (
    checkCareOfFieldsAreEqual(address, initialAddress) &&
    address.postal?.districtName === initialAddress.postal?.districtName &&
    address.postal?.code === initialAddress.postal?.code &&
    address.country?.name === initialAddress.country?.name
  );
};

const checkCommonRequiredFields = address => {
  return (
    address.postal?.districtName?.length > 0 &&
    address.postal?.code?.length > 0 &&
    address.country?.name?.length > 0
  );
};

const handleSuccessfulCreateResult = (
  address,
  salesId,
  isModalOpenedOnSalesIdLevel,
  dispatch
) => {
  ServiceClient.getAddressListForSalesId(salesId, list => {
    dispatch({
      type: `${
        isModalOpenedOnSalesIdLevel
          ? 'setNewAddressForSalesId'
          : 'setNewAddressForSalesCode'
      }`,
      address,
      list,
    });
  });
};
