import React, { Component } from 'react';
import Name from '../../Elements/Name';
import SalesID from '../../Elements/SalesID';
import UserId from '../../Elements/UserId';
import BankAccountNumber from '../../Elements/BankAccountNumber';
import { PresentationMode } from '../../Enums/PresentationMode';
import './PersonInfo.css';
import { FormattedMessage } from 'react-intl';
import Messages from '../../Localization/Messages';
import { BusinessArea } from '../../Enums/BusinessArea';
import { getBusinessAreaByChannel } from '../../Helpers/SalesCodeHelper';
import BankAccountType from '../../Elements/BankAccountType';

class PersonInfo extends Component {
  state = {
    name: this.props.item?.name ?? this.props.newName,
    draftName: this.props.item?.name ?? this.props.newName,
    salesId: this.props.item?.salesId ?? this.props.newSalesId,
    draftSalesId: this.props.item?.salesId ?? this.props.newSalesId,
    bankAccountNo: this.props.item?.bankAccount?.number,
    draftBankAccountNo: this.props.item?.bankAccount?.number,
    bankAccountType: this.props.item?.bankAccount?.type,
    draftBankAccountType: this.props.item?.bankAccount?.type,
    bankName: this.props.item?.bankAccount?.bankName,
    userId: this.props.item?.userId ?? this.props.newUserId ?? '',
    draftUserId: this.props.item?.userId ?? this.props.newUserId ?? '',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.item?.name !== this.props.item?.name) {
      this.setState({ name: this.props.item?.name });
    }
    if (prevProps.item?.salesId !== this.props.item?.salesId) {
      this.setState({ salesId: this.props.item?.salesId });
    }
    if (
      prevProps.item?.bankAccount?.number !==
      this.props.item?.bankAccount?.number
    ) {
      this.setState({
        bankAccountNo: this.props.item?.bankAccount?.number,
        bankAccountType: this.props.item?.bankAccount?.type,
        bankName: this.props.item?.bankAccount?.bankName,
      });
    }
    if (prevProps.item?.userId !== this.props.item?.userId) {
      this.setState({ userId: this.props.item?.userId });
    }
  }

  resetDraftValues() {
    this.setState(prevState => ({
      draftName: prevState.Name,
      draftBankAccountNo: prevState.bankAccountNo,
      draftBankAccountType: prevState.bankAccountType,
    }));
  }

  render() {
    return (
      <div className="relax-grid person-info-main">
        <form>
          <div className="relax-row">
            <div className="person-info-sub">
              <div>
                <div className="personIcon"></div>
              </div>
              <div>
                <SalesID
                  mode={this.props.mode}
                  onChangeValue={draftSalesId => {
                    this.setState({ draftSalesId });
                  }}
                  disabled={!this.props.enableEditSalesId}>
                  {this.state.salesId}
                </SalesID>
              </div>
              <div>
                <UserId
                  mode={this.props.mode}
                  onChangeValue={draftUserId => {
                    this.setState({ draftUserId });
                  }}>
                  {this.state.userId}
                </UserId>
              </div>
              {this.props.isDecoupledFunctionsAvailable && (
                <div>
                  <Name
                    mode={this.props.mode}
                    onChangeValue={draftName => {
                      this.setState({ draftName });
                    }}>
                    {this.state.name}
                  </Name>
                </div>
              )}
              {this.props.isBrokerFunctionsAvailable && (
                <>
                  <div>
                    <BankAccountNumber
                      mode={this.props.mode}
                      onChangeValue={draftBankAccountNo => {
                        this.setState({ draftBankAccountNo });
                      }}>
                      {this.state.bankAccountNo}
                    </BankAccountNumber>
                  </div>
                  {this.showBankAccountType() && (
                    <div>
                      <BankAccountType
                        onChangeValue={draftBankAccountType => {
                          this.setState({ draftBankAccountType });
                        }}
                        mode={this.props.mode}
                        bankName={this.state.bankName}>
                        {this.state.draftBankAccountType}
                      </BankAccountType>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {this.props.mode === PresentationMode.Edit ? (
            <div className="relax-row">
              <div className="person-buttons-container">
                <button
                  type="button"
                  className="relax-button relax-button--secondary"
                  onClick={event => {
                    this.resetDraftValues();
                    this.props.handleCancelButton();
                  }}>
                  <FormattedMessage {...Messages.cancel} />
                </button>
                <button
                  disabled={!this.isValidSaveButton()}
                  type="submit"
                  className="relax-button relax-button--primary"
                  name="source"
                  onClick={event => {
                    event.preventDefault();
                    this.props.handleSaveButton(
                      this.state.draftSalesId,
                      this.state.draftUserId,
                      this.state.draftName,
                      this.state.draftBankAccountNo,
                      this.state.draftBankAccountType
                    );
                  }}>
                  <FormattedMessage {...Messages.save} />
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </form>
      </div>
    );
  }

  isValidSaveButton() {
    return (
      this.isSalesIdValid() && ((
          this.props.isDecoupledFunctionsAvailable &&
          this.isNameValid() && (
            this.isNameChanged() ||
            this.isUserIdChanged() ||
            this.isDraftSalesId()
          )
        ) || (
          this.props.isBrokerFunctionsAvailable && ((
              !this.props.isBankAccountMandatory &&
              this.isUserIdChanged()
            ) || (
              this.isBankAccountValid() && (
                this.isBankDetailsChanged() ||
                this.isUserIdChanged()
              )
            )
          )
        )
      )
    );
  }

  isDraftSalesId() {
    return !this.props.item?.salesCodes;
  }

  isBankDetailsChanged() {
    return (
      this.state.draftBankAccountNo !== this.state.bankAccountNo ||
      this.state.draftBankAccountType?.toString() !==
        this.state.bankAccountType?.toString()
    );
  }

  isNameChanged() {
    return this.state.name !== this.state.draftName;
  }

  isUserIdChanged() {
    return this.state.userId?.trim() !== this.state.draftUserId?.trim();
  }

  isSalesIdValid() {
    return !!this.state.draftSalesId?.trim();
  }

  isNameValid() {
    return !!this.state.draftName?.trim();
  }

  isBusinessAreaPrivate() {
    return (
      getBusinessAreaByChannel(this.props.item?.salesCodes[0]?.channel) ===
      BusinessArea.Private
    );
  }

  isBankAccountValid() {
    return (
      !!this.state.draftBankAccountNo?.trim() && this.state.draftBankAccountType
    );
  }

  isSalesCodeExist() {
    return this.props.item?.salesCodes;
  }

  showBankAccountType() {
    return (
      !!this.state.bankAccountNo || this.props.mode === PresentationMode.Edit
    );
  }
}

export default PersonInfo;
