import React, { Component } from 'react';
import { Tile } from '../../Elements/Tiles';
import { ExternalSalesCode } from '../../Elements/ExternalSalesCode';
import SalesCodeDetails from '../SalesCodeDetails/SalesCodeDetails';
import IconWithButton from '../../Elements/IconWithButton';
import { MenuList, MenuListItem } from '@customer/react-relax';
import { OutsideClickHandler } from '../../Elements/OutsideClickHandler';
import { PresentationMode } from '../../Enums/PresentationMode';
import RevokeConfirmation from '../RevokeConfirmation/RevokeConfirmation';
import {
  getBusinessAreaByChannel,
  isSalesCodeActive,
} from '../../Helpers/SalesCodeHelper';
import { Redirect } from 'react-router-dom';
import { ServiceClient } from '../../Services/ServiceClient';
import { formatMessage } from '../../Localization/formatMessage';
import Messages from '../../Localization/Messages';
import { capitalizeFirstWord } from '../../Helpers/Converter';
import './ActiveSalesCodes.css';
import BankAccountHistory from '../BankAccountHistory/BankAccountHistory';
import EditBankAccountOnSalesCode from '../EditBankAccountOnSalesCode/EditBankAccountOnSalesCode';
import { GlobalContext } from '../../GlobalContext';
import { BusinessArea } from '../../Enums/BusinessArea';

class ActiveSalesCodes extends Component {
  static contextType = GlobalContext;

  state = {
    editWindowIsShown: false,
    editWindowSalesCodeItem: null,
    editWindowMode: null,
    revokeConfirmationIsShown: false,
    showBankAccountLog: false,
    bankAccountLogSalesCode: null,
    showBankAccountEdit: false,
    bankAccountEditSalesCode: null,
    showBrokerCostRates: false,
    brokerCostRatesSalesCode: null,
    salesCodesToRevoke: [],
    renderCommissionRedirect: false,
    commissionRedirectPayload: {},
  };

  componentDidMount() {
    this.showSalesCodeWindow(this.props.editingSalesCode);
  }

  componentDidUpdate(prevProps) {
    if (this.props.editingSalesCode !== prevProps.editingSalesCode) {
      this.showSalesCodeWindow(this.props.editingSalesCode);
    }
  }

  showSalesCodeWindow(salesCode) {
    var allMatching =
      this.props.item?.salesCodes?.filter(s => s.salesCode === salesCode) ?? [];
    // sort descending by validFrom
    allMatching.sort((a, b) => (a.validFrom > b.validFrom ? -1 : 1));
    // if no active records for salescode, use latest not active
    var salesCodeItem = allMatching
      .filter(s => isSalesCodeActive(s))
      .concat(allMatching.filter(s => !isSalesCodeActive(s)))[0];

    this.setState({
      editWindowIsShown: !!salesCodeItem,
      editWindowSalesCodeItem: salesCodeItem,
      editWindowMode:
        salesCodeItem && isSalesCodeActive(salesCodeItem)
          ? PresentationMode.Edit
          : PresentationMode.View,
    });
  }

  render() {
    const activeSalesCodes = this.props.item?.salesCodes?.filter(
      isSalesCodeActive
    );
    return (
      <Tile
        embed={true}
        isUsingTitle={true}
        overflow={true}
        header={formatMessage(Messages.activeSalesCodes)}>
        {/* Redirect to PersonPage of new sales id */}
        {this.state.renderViewRedirect && (
          <Redirect
            to={{
              pathname: `/person/view/${this.state.viewRedirectPayload.salesId}`,
              state: {
                item: this.state.viewRedirectPayload,
              },
            }}
          />
        )}
        {this.state.renderCommissionRedirect && (
          <Redirect
            to={{
              pathname: `/person/commission/${this.state.commissionRedirectPayload.salesCode}`,
            }}
          />
        )}
        <BankAccountHistory
          isShown={this.state.showBankAccountLog}
          salesCode={this.state.bankAccountLogSalesCode}
          setIsShown={x =>
            this.setState({ showBankAccountLog: x })
          }></BankAccountHistory>
        <SalesCodeDetails
          isShown={this.state.editWindowIsShown}
          setIsShown={val => this.setState({ editWindowIsShown: val })}
          mode={this.state.editWindowMode}
          item={this.props.item}
          salesCodeItem={this.state.editWindowSalesCodeItem}
          saveCallback={this.handleSaveSalesCodeButton}
          isBrokerFunctionsAvailable={this.props.isBrokerFunctionsAvailable}
          isDecoupledFunctionsAvailable={
            this.props.isDecoupledFunctionsAvailable
          }></SalesCodeDetails>
        <RevokeConfirmation
          isShown={this.state.revokeConfirmationIsShown}
          setIsShown={val => this.setState({ revokeConfirmationIsShown: val })}
          revokeCallback={() => {
            ServiceClient.revokeSalesCode(
              this.props.item.id,
              this.state.salesCodesToRevoke[0].salesCode,
              () => {
                this.props.personReload(false, true);
              }
            );
          }}
          salesCodes={this.state.salesCodesToRevoke}></RevokeConfirmation>
        <EditBankAccountOnSalesCode
          isShown={this.state.showBankAccountEdit}
          salesCode={this.state.bankAccountEditSalesCode}
          setIsShown={x =>
            this.setState({ showBankAccountEdit: x })
          }></EditBankAccountOnSalesCode>

        {activeSalesCodes && activeSalesCodes.length > 0 && (
          <table className="active-sales-codes-table relax-table relax-table--striped">
            <thead>
              <tr>
                <th>{formatMessage(Messages.salesCode)}</th>
                <th>{formatMessage(Messages.costCenter)}</th>
                <th>{formatMessage(Messages.externalSalesCode)}</th>
                <th>{formatMessage(Messages.additionalName)}</th>
                <th>{formatMessage(Messages.activationDate)}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {activeSalesCodes.map(salescode => (
                <tr key={salescode.id}>
                  <td>{salescode.salesCode}</td>
                  <td>{salescode.costCenter?.name}</td>
                  <td>
                    <ExternalSalesCode>
                      {salescode.externalSalesCode}
                    </ExternalSalesCode>
                  </td>
                  {(getBusinessAreaByChannel(salescode.channel) ??
                    this.context?.businessArea) === BusinessArea.Commercial ? (
                    <td>{salescode.additionalName}</td>
                  ) : (
                    <td className="not-applicable">
                      {formatMessage(Messages.notApplicable)}
                    </td>
                  )}
                  <td>{salescode.validFrom}</td>
                  <td>
                    <button
                      id="person-actions-menu"
                      type="button"
                      className="salescode-tile-menu"
                      onClick={() => {
                        this.setState({
                          showMenuForSalesCode: salescode.salesCode,
                        });
                      }}
                    />
                    <OutsideClickHandler
                      onOutsideClick={() => {
                        if (
                          this.state.showMenuForSalesCode ===
                          salescode.salesCode
                        ) {
                          this.setState({
                            showMenuForSalesCode: null,
                          });
                        }
                      }}>
                      <MenuList
                        isOpen={
                          this.state.showMenuForSalesCode ===
                          salescode.salesCode
                        }
                        className="active-sales-code-menulist">
                        <MenuListItem
                          name={formatMessage(Messages.edit)}
                          onClick={() => {
                            this.setState({
                              editWindowIsShown: true,
                              editWindowMode: PresentationMode.Edit,
                              editWindowSalesCodeItem: salescode,
                              showMenuForSalesCode: null,
                            });
                          }}>
                          {formatMessage(Messages.edit)}
                        </MenuListItem>
                        {this.props.isBrokerFunctionsAvailable && (
                          <MenuListItem
                            name={formatMessage(Messages.editBankAccount)}
                            onClick={() => {
                              this.setState({
                                showBankAccountEdit: true,
                                bankAccountEditSalesCode: salescode.salesCode,
                                showMenuForSalesCode: null,
                              });
                            }}>
                            {formatMessage(Messages.editBankAccount)}
                          </MenuListItem>
                        )}
                        {this.props.isBrokerFunctionsAvailable && (
                          <MenuListItem
                            name={formatMessage(Messages.viewBankAccountLog)}
                            onClick={() => {
                              this.setState({
                                showBankAccountLog: true,
                                bankAccountLogSalesCode: salescode.salesCode,
                                showMenuForSalesCode: null,
                              });
                            }}>
                            {formatMessage(Messages.viewBankAccountLog)}
                          </MenuListItem>
                        )}
                        {this.props.isBrokerFunctionsAvailable && (
                          <MenuListItem
                            name={formatMessage(Messages.viewBrokerCostRates)}
                            onClick={() => {
                              this.setState({
                                renderCommissionRedirect: true,
                                commissionRedirectPayload: {
                                  salesCode: salescode.salesCode,
                                },
                              });
                            }}>
                            {formatMessage(Messages.viewBankAccountLog)}
                          </MenuListItem>
                        )}
                        <MenuListItem
                          name={formatMessage(Messages.revoke)}
                          onClick={() => {
                            this.setState({
                              showMenuForSalesCode: null,
                              salesCodesToRevoke: [salescode],
                              revokeConfirmationIsShown: true,
                            });
                          }}></MenuListItem>
                        <MenuListItem
                          name={formatMessage(Messages.editAddress)}
                          onClick={() => {
                            this.props.openAddressTab(salescode.salesCode);
                            this.setState({
                              showMenuForSalesCode: null,
                            });
                          }}>
                          {formatMessage(Messages.editAddress)}
                        </MenuListItem>
                      </MenuList>
                    </OutsideClickHandler>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <IconWithButton
          title={capitalizeFirstWord(formatMessage(Messages.addSalesCode))}
          buttonText={formatMessage(Messages.create)}
          showButton={true}
          disabled={this.props.item == null}
          alignTop={activeSalesCodes && activeSalesCodes.length}
          onButtonClick={() => {
            this.setState({
              editWindowIsShown: true,
              editWindowMode: PresentationMode.Add,
              editWindowSalesCodeItem: null,
            });
          }}
          iconName="headphones-mic"></IconWithButton>
      </Tile>
    );
  }

  handleSaveSalesCodeButton = (data, successCallback, errorCallback) => {
    if (this.state.editWindowMode === PresentationMode.Add) {
      //Check if new person
      if (!this.props.item.id) {
        //call api for create salesid then after success call create salescode
        ServiceClient.createSalesAgent(
          {
            salesId: (
              this.props.item?.salesId ?? this.props.newSalesId
            )?.toUpperCase(),
            userId: this.props.item.userId ?? this.props.newUserId,
            name: this.props.item.name,
            bankAccountNo: this.props.item.bankAccountNo,
            type: this.props.item.bankAccountType,
            salesCodes: [
              {
                costCenterId: data.selectedCostCenterId,
                email: data.email,
                phone: data.phone,
                externalSalesCode: data.externalSalesCode,
                additionalName: data.additionalName,
                canBePortfolioResponsible: data.canBePortfolioResponsible,
                extension: {
                  groupEmail: data.groupEmail,
                  availableForXmlExport: data.availableForXmlExport,
                  isDigitalCustomer: data.isDigitalCustomer,
                },
              },
            ],
          },
          result => {
            successCallback();
            this.setState({
              viewRedirectPayload: result,
              renderViewRedirect: true,
            });
          },
          errorCallback
        );
      } else {
        ServiceClient.createSalesCode(
          this.props.item.id,
          data.selectedCostCenterId,
          data.externalSalesCode,
          data.additionalName,
          data.email,
          data.phone,
          data.canBePortfolioResponsible,
          {
            groupEmail: data.groupEmail,
            availableForXmlExport: data.availableForXmlExport,
            isDigitalCustomer: data.isDigitalCustomer,
          },
          createdSalesItem => {
            this.props.addSalesCode(createdSalesItem);
            successCallback();
          },
          errorCallback
        );
      }
    }

    if (this.state.editWindowMode === PresentationMode.Edit) {
      ServiceClient.editSalesCode(
        this.props.item.id,
        this.state.editWindowSalesCodeItem.salesCode,
        data.selectedCostCenterId,
        data.externalSalesCode,
        data.additionalName,
        data.email,
        data.phone,
        data.canBePortfolioResponsible,
        {
          groupEmail: data.groupEmail,
          availableForXmlExport: data.availableForXmlExport,
          isDigitalCustomer: data.isDigitalCustomer,
        },
        () => {
          this.props.personReload();
          if (successCallback) successCallback();
        },
        errorCallback
      );
    }
  };
}

export default ActiveSalesCodes;
