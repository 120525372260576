import React from 'react';
import { addressActions } from '../../AddressConstants';
import AddressInheritanceCheckBox from '../AddressInheritanceCheckBox';
import ExistingAddressSelector from '../ExistingAddressSelector';
import { useGlobalAddressContext } from '../../AddressProvider';
import { handleClick } from './AddressViewTopFunctions';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const AddressViewTop = () => {
  const {
    addressAction,
    isModalOpenedOnSalesIdLevel,
    addressState,
    salesCode,
    salesId,
    dispatch,
    setAddressAction,
  } = useGlobalAddressContext();

  return (
    <>
      {addressAction !== addressActions.Create &&
        addressAction !== addressActions.Edit && (
          <>
            <div className="relax-row max-width" style={{ marginLeft: '0px' }}>
              <div className="relax-col-9--xs">
                <div className="relax-row">
                  {!isModalOpenedOnSalesIdLevel && (
                    <AddressInheritanceCheckBox />
                  )}
                </div>
                <div className="relax-row">
                  {isModalOpenedOnSalesIdLevel ||
                  (!isModalOpenedOnSalesIdLevel &&
                    !addressState.isInherited) ? (
                    <ExistingAddressSelector />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="relax-col-3--xs">
                <button
                  type="button"
                  disabled={!addressState.hasAddressUpdated}
                  onClick={() => {
                    handleClick(
                      salesCode,
                      salesId,
                      addressState,
                      dispatch,
                      setAddressAction,
                      isModalOpenedOnSalesIdLevel
                    );
                  }}
                  className="relax-button relax-button--primary address-update-button">
                  {formatMessage(Messages.update)}
                </button>
              </div>
            </div>

            <div className="divider-line"></div>
          </>
        )}
    </>
  );
};

export default AddressViewTop;
