const getString = (configVariableName, defaultValue) => {
  return configVariableName.startsWith('#') && configVariableName.endsWith('#')
    ? defaultValue
    : configVariableName;
};

const config = {
  environment: getString('#{Environment}#', 'dev'),
  source: 'Waypoint Sales Agent App',
  // NOTE: Remember to add on-premise APIs to the list at the bottom of this file
  authority: getString('#{Authority.Url}#', 'https://idp.test.if.eu/idsrv'),
  components: {
    globalHeader: getString(
      '#{GlobalHeader.Url}#',
      'https://waypoint-customer-stest-global-header.azurewebsites.net/global-header.js'
    ),
  },
  apis: {
    Customer: getString(
      '#{Customer.Api}#',
      'https://waypoint-stest.ifint.biz:2001/api/customer/sales-agent-app'
      //'http://localhost/wip/api/customer/sales-agent-app'
    ),
    WaypointPolicy: getString(
      '#{WaypointPolicy.Api}#',
      'https://waypoint-stest.ifint.biz:2001/api/Policy'
      //'http://localhost/wip/api/Policy'
    ),
      SalesAgent: getString(
      '#{SalesAgent.Api}#',
      //'https://localhost:44322/sales-agent-app'
      'https://waypoint-stest.ifint.biz:2001/SalesAgent.Api/sales-agent-app'
    )
  },
};

export const storageKeys = {
  locale: 'last_used_locale',
  legalUnit: 'last_used_legal_unit',
  businessArea: 'business_Area',
};

export const defaultLanguageCode = 'en';

export default config;
