export const addressReducer = (addressState, action) => {
  if (action.type === 'clearAddressFields') {
    return { ...addressState, selectedAddress: {} };
  }

  if (action.type === 'setInitialAddressStateSalesId') {
    return {
      ...addressState,
      uneditedData: { address: action.salesIdDto.postalAddress },
      selectedAddress: action.salesIdDto.postalAddress,
      hasAddressUpdated: false,
    };
  }
  if (action.type === 'setInitialAddressStateSalesCode') {
    return {
      ...addressState,
      uneditedData: {
        address: action.salesCodeDto.address,
        isInherited: action.salesCodeDto.inherited,
      },
      selectedAddress: action.salesCodeDto.address,
      hasAddressUpdated: false,
      isInherited: action.salesCodeDto.inherited,
      salesIdAddress: action.salesIdDto.postalAddress,
    };
  }

  if (action.type === 'resetSelectedAddress') {
    return {
      ...addressState,
      isInherited: addressState.uneditedData.isInherited,
      selectedAddress: addressState.uneditedData.address,
      hasAddressUpdated: false,
    };
  }

  if (action.type === 'setAddressList') {
    return {
      ...addressState,
      addressList: action.addressList,
    };
  }

  if (action.type === 'changeInheritance') {
    const hasAddressUpdated = addressState.uneditedData.isInherited
      ? addressState.isInherited
      : !addressState.isInherited;

    const newAddress = addressState.isInherited
      ? addressState.uneditedData.address
      : addressState.salesIdAddress;

    return {
      ...addressState,
      hasAddressUpdated,
      selectedAddress: newAddress,
      isInherited: !addressState.isInherited,
    };
  }

  if (action.type === 'changeHasAddressUpdated') {
    const address = addressState.addressList.find(
      x => x.id === action.addressId
    );

    const hasAddressUpdated = addressState.uneditedData.isInherited
      ? !addressState.isInherited
      : action.addressId !== addressState.uneditedData.address.id;

    return {
      ...addressState,
      hasAddressUpdated,
      selectedAddress: address,
    };
  }

  if (action.type === 'inheritAddressFromSalesId') {
    return {
      ...addressState,
      selectedAddress: addressState.salesIdAddress,
      uneditedData: { address: addressState.salesIdAddress, isInherited: true },
      hasAddressUpdated: false,
      isInherited: true,
      addressList: action.list,
    };
  }

  if (action.type === 'setSelectedAddress') {
    return {
      ...addressState,
      uneditedData: {
        address: addressState.selectedAddress,
        isInherited: false,
      },
      hasAddressUpdated: false,
      isInherited: false,
      addressList: action.list,
    };
  }

  if (action.type === 'setNewAddressForSalesCode') {
    return {
      ...addressState,
      selectedAddress: action.address,
      isInherited: false,
      hasAddressUpdated: false,
      uneditedData: {
        address: action.address,
        isInherited: false,
      },
      addressList: action.list,
    };
  }

  if (action.type === 'setNewAddressForSalesId') {
    return {
      ...addressState,
      selectedAddress: action.address,
      uneditedData: {
        address: action.address,
      },
      addressList: action.list,
    };
  }

  throw Error('Unknown action.', action);
};
