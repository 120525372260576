import { ServiceClient } from '../../Services/ServiceClient';
import { addressFormats } from './AddressConstants';

export const changeAddressForSalesCode = (
  salesCode,
  salesId,
  addressId,
  dispatch
) => {
  ServiceClient.setAddressForSalesCode(salesCode, addressId, () => {
    ServiceClient.getAddressListForSalesId(salesId, list => {
      dispatch({ type: 'setSelectedAddress', list });
    });
  });
};

export const inheritAddressFromSalesId = (salesCode, salesId, dispatch) => {
  ServiceClient.inheritAddressFromSalesId(salesCode, () => {
    ServiceClient.getAddressListForSalesId(salesId, list => {
      dispatch({ type: 'inheritAddressFromSalesId', list });
    });
  });
};

export const getFormattedAddress = address => {
  if (address.format === addressFormats.Street)
    return `${address.format} ${address.streetName} ${address.houseNumber}${address.houseLetter} `;
  if (address.format === addressFormats.Foreign)
    return `${address.format} ${address.addressLine1} ${address.addressLine2} ${address.addressLine3}`;
  if (address.format === addressFormats.POBox)
    return `${address.format} ${address.pOBoxNumber} ${address.pOBoxFacilityName}`;
  if (address.format === addressFormats.Freetext)
    return `${address.format} ${address.additionalAddress}`;
  if (address.format === addressFormats.Unstructured)
    return `${address.format} ${address.addressLine1} ${address.addressLine2} ${address.addressLine3}`;
};
