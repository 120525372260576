import React from 'react';
import './Error.css';
import { formatMessage } from '../../../../Localization/formatMessage';
import Messages from '../../../../Localization/Messages';

const Error = () => {
  return (
    <p className="error-paragraph">
      {formatMessage(Messages.addressComponentError)}
    </p>
  );
};

export default Error;
